<template>
  <div class="flex flex-col items-center">
    <div class="w-full xl:w-1/2 space-y-2">
      <a-button @click="$router.back()">
        {{ lang.common.back }}
      </a-button>

      <form
        class="space-y-4"
        @submit.prevent="handleUpdate"
        @keydown="removeError($event.target.id)">
        <h3 class="my-4 text-2xl">
          {{ lang.activities.update_activity }}
        </h3>
        <a-input
              :label="lang.activities.table.title"
              required
              class="mb-4"
              id="title"
              :disabled="loading"
              :error="error('title')"
          v-model="activity.title" />

        <a-input
          :label="lang.activities.table.description"
          required
          class="mb-4"
          id="description"
          :disabled="loading"
          :error="error('description')"
          v-model="activity.description" />

        <a-input
          :label="lang.activities.table.start_date"
          required
          class="mb-4"
          id="start_date"
          :disabled="loading"
          type="date"
          :error="error('start_date')"
          v-model="activity.start_date" />

        <a-input
          :label="lang.activities.table.end_date"
          class="mb-4"
          id="end_date"
          :disabled="loading"
          type="date"
          :error="error('end_date')"
          v-model="activity.end_date" />

        <a-input
          :label="lang.activities.table.observation"
          id="observation"
          class="mb-4"
          type="textarea"
          :disabled="loading"
          v-model="activity.observation" />

        <small v-if="activity.start_date > activity.end_date" class="text-red-400">La fecha de inicio no puede ser posterior a la de finalización</small>
        <div class="space-x-2 flex">
          <a-button mode="primary" type="submit" :loading="loading" :disabled="activity.start_date > activity.end_date">
            {{ lang.common.save }}
          </a-button>

          <a-button outlined @click="$router.back()">
            {{ lang.common.cancel }}
          </a-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
  data: () => ({
    activity: {
      title: '',
      description: '',
      start_date: null,
      end_date: null
    }
  }),
  computed: {
    ...mapGetters(['hasError', 'error', 'lang']),
    ...mapState({
      activities: state => state.activities.pagination.data,
      currentActivityOnState: state => state.activities.current,
      loading: state => state.activities.loading
    })
  },
  methods: {
    ...mapActions({
      fetchActivity: 'activities/show',
      update: 'activities/update',
      removeError: 'removeError',
    }),
    handleUpdate() {
      this.update(this.activity)
        .then(() => {
          this.$router.back()
        })
    }
  },
  watch: {
    currentActivityOnState(val) {
      this.activity = {...val}
    }
  },
  created() {
    const { id } = this.$route.params
    this.fetchActivity(id)
  }
}
</script>
